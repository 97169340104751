<template>
  <div class="ss-assessment-verification">
    <div class="row">
      <div class="col col-left col-top">
        <span class="title">基本信息填写完整：</span>
        <span v-if="check && !basis.length" class="content pass">通过</span>
        <div v-else class="list">
          <span v-for="(item, idx) of basis" :key="idx" class="content">{{ item }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-left col-top">
        <span class="title">题目配置：</span>
        <span v-if="check && !required.length" class="content pass">通过</span>
        <div v-else class="list">
          <span v-for="(item, idx) of required" :key="idx" class="content">{{ item }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-left col-top">
        <span class="title">模式配置：</span>
        <span v-if="check && !questionNum.length" class="content pass">通过</span>
        <div v-else class="list">
          <span v-for="(item, idx) of questionNum" :key="idx" class="content">{{ item }}</span>
        </div>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col col-left">
        <a-button v-if="status === 1" type="primary" @click="pullOff">下架</a-button>
        <a-button v-else type="primary" :disabled="!check || !allowPullOn" @click="pullOn">上架</a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  pullOnSkillGroup,
  pullOffSkillGroup,
  getSkillGroupCompleteStatus,
} from '../api';

export default {
  name: 'Verification',
  computed: {
    ...mapState({
      readOnly: state => state.Assessment.readOnly,
      assessment: state => state.Assessment.assessment,
    }),
    status() {
      return this.assessment.status;
    },
    allowPullOn() {
      const basis = !this.basis.length;
      const required = !this.required.length;
      const questionNum = !this.questionNum.length;

      return !this.readOnly && basis && required && questionNum;
    },
    basis() {
      return this.check?.basis || [];
    },
    required() {
      return this.check?.required || [];
    },
    questionNum() {
      return this.check?.question_num || [];
    },
  },
  data() {
    return {
      id: null, // 话术 id
      loading: false,

      check: null,
    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.getSkillGroupCompleteStatus();
    this.$store.dispatch('Assessment/getAssessment', this.id);
  },
  destroyed() {
    this.$store.commit('Assessment/updateReadOnly', true);
    this.$store.commit('Assessment/updateAssessmentId', 0);
    this.$store.commit('Assessment/updateAssessment', {});
  },
  methods: {
    cancel() {
      this.$router.back();
    },

    async getSkillGroupCompleteStatus() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getSkillGroupCompleteStatus({ id: this.id }).finally(() => {
        this.loading = false;
      });
      this.check = data?.data || {};
    },

    pullOn() {
      this.$confirm({
        content: () => <div>确认上架？</div>,
        onOk: () => {
          this.confirmPullOn();
        },
      });
    },
    async confirmPullOn() {
      this.loading = true;
      const data = await pullOnSkillGroup({ id: this.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '上架失败' });
        return;
      }
      this.$message.success({ content: data?.message || '上架成功' });
      this.cancel();
    },

    pullOff() {
      this.$confirm({
        content: () => <div>确认下架？</div>,
        onOk: () => {
          this.confirmPullOff();
        },
      });
    },
    async confirmPullOff() {
      this.loading = true;
      const data = await pullOffSkillGroup({ id: this.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          const basis = data?.data?.basis || [];
          const required = data?.data?.required || [];
          const question_num = data?.data?.question_num || [];
          const list = [].concat(...basis, ...required, ...question_num);
          this.$notice({
            title: data?.message || '下架失败',
            content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i || ''))]),
          });
          return;
        }
        this.$message.error({ content: data?.message || '下架失败' });
        return;
      }
      this.$message.success({ content: data?.message || '下架成功' });
      this.$store.dispatch('Assessment/getAssessment', this.id);
    },
  },
};
</script>

<style scoped lang="scss">
.ss-assessment-verification {
  width: 100%;

  .row {
    max-width: 100%;
    margin: 20px 0;
    padding: 0;

    .col {
      width: 100%;
      max-width: 100%;
      padding: 0;

      .title {
        display: inline-block;
        width: 160px;
        text-align: right;
      }

      .list {
        display: flex;
        flex-direction: column;
      }

      .pass {
        color: $success01;
      }
    }
  }

}
</style>
