<template>
  <div class="full-width">
    <div class="row row-left">
      <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
        <a-menu-item v-for="item of menus" :disabled="item.id !== '1' && !id" :key="item.id">
          {{ item.title }}
        </a-menu-item>
      </a-menu>

      <div v-if="readOnly" class="col ml-20">
        <span class="sub-txt">将话术下架后才能进行编辑</span>
      </div>
    </div>

    <div class="row">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Detail',
  computed: {
    ...mapState({
      readOnly: state => state.Script.readOnly,
    }),
    menus() {
      return [
        {
          id: '1',
          title: '话术配置',
          path: `/index/speechcraft/skill/${this.pageType}/config`,
        },
        {
          id: '2',
          title: '关键词设置',
          path: `/index/speechcraft/skill/${this.pageType}/keyword`,
        },
        {
          id: '3',
          title: '练习设置',
          path: `/index/speechcraft/skill/${this.pageType}/practice`,
        },
        {
          id: '4',
          title: '配置校验',
          path: `/index/speechcraft/skill/${this.pageType}/verification`,
        },
      ];
    },
  },
  watch: {
    '$route.query.id'(id) {
      this.id = id || this.id;
    },
    '$route.path'(path) {
      this.executeSelection(path);
    },
  },
  data() {
    return {
      id: null,
      pageType: 'detail',
      selectedMenu: ['1'],
    };
  },
  created() {
    this.pageType = this.$route.path.match(/(create|edit|detail)/g)?.[0] || 'detail';
    this.id = this.$route.query?.id || null;
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.executeSelection(to?.path || ''));
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.query?.id || null;
    this.executeSelection(to?.path || '');
    next();
  },
  methods: {
    executeSelection(path) {
      this.menus.forEach(menu => {
        if (menu.path === path) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({ key }) {
      this.id = this.$route.query?.id || null;
      if (+key > 1 && !this.id) {
        this.selectedMenu = ['1'];
        this.$message.warning({ content: '请先保存' });
        return;
      }
      const path = this.getPath(key) + (this.id ? `?id=${this.id}` : '');
      this.$router.replace(path);
    },
    getPath(key) {
      const [{ path }] = this.menus.filter(menu => menu.id === key + '' && menu.path);
      return path || '';
    },
  },
};
</script>

<style scoped lang="scss">
.sub-txt {
  font-size: 12px;
  color: $light02;
}
</style>
