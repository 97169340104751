import request from '@/utils/Request';

// 话术-关键词列表
export function getKeywords(params) {
  return request({
    url: '/speechskill/admin/keyword/index',
    method: 'get',
    params
  });
}

// 话术-关键词保存
export function saveKeywords(data) {
  return request({
    url: '/speechskill/admin/keyword/save',
    method: 'post',
    data
  });
}

// 话术-关键词删除
export function deleteKeywords(data) {
  return request({
    url: '/speechskill/admin/keyword/delete',
    method: 'post',
    data
  });
}

// 话术-关键词加粗
export function boldKeyword(data) {
  data = data || {};
  data.field = 'is_keyword_bold';
  return request({
    url: '/speechskill/admin/skill/updfield',
    method: 'post',
    data
  });
}
