<template>
  <div class="ss-script-config">
    <div class="row">
      <div class="col col-left post pl-2em">
        <span class="red">*</span>
        <span>类别：</span>
        <SelectScriptCategory :show-label="false" :disabled="readOnly" :default-selected-ids="defaultCategory"
                              @select="selectScriptCategory"></SelectScriptCategory>
      </div>
    </div>

    <div class="row">
      <div class="col col-left">
        <span class="red">*</span>
        <span>题干文案：</span>
        <a-textarea v-model="title" placeholder="请输入问题" :disabled="readOnly"
                    :auto-size="{ minRows: 3, maxRows: 12 }"></a-textarea>
      </div>
    </div>

    <div class="row">
      <div class="col col-left">
        <span class="red">*</span>
        <span>话术文案：</span>
        <a-textarea v-model="content" :disabled="readOnly" placeholder="请输入话术" class="script-content"
                    :auto-size="{ minRows: 4, maxRows: 12 }"></a-textarea>
      </div>
    </div>

    <div class="row">
      <div class="col col-left pl-2em">
        <span>配图：</span>
        <UploadImage v-model="imageList"/>
      </div>
    </div>

    <div class="row">
      <div class="col col-left post pl-2em">
        <span class="red">*</span>
        <span>岗位：</span>
        <app-select-job v-model="selectedJobIds"
                        :disabled="readOnly"
                        placeholder="请选择岗位"/>
      </div>
    </div>
    <div class="flex flex-start" style="padding-left: 75px;">
      <app-select-base placeholder="若使用其他话术设置好的岗位，请选择话术"
                       :disabled="readOnly"
                       mode="default"
                       :list="skillList"
                       :options="{id: 'id', name: 'title'}"
                       :fixed-tags="['div']"
                       style="min-width: 360px; width: 100%;"
                       @change="setField('selectedJobIds', $event)"/>
    </div>

    <div class="row">
      <div class="col col-left">
        <span class="red">*</span>
        <span>组织机构：</span>
        <app-select-org v-model="selectedOrgIds"
                        :disabled="readOnly"
                        placeholder="请选择组织机构"
                        class="select-org"/>
      </div>
    </div>
    <div class="flex flex-start" style="padding-left: 75px;">
      <app-select-base placeholder="若使用其他话术设置好的岗位，请选择话术"
                       :disabled="readOnly"
                       mode="default"
                       :list="skillList"
                       :options="{id: 'id', name: 'title'}"
                       :fixed-tags="['div']"
                       style="min-width: 360px; width: 100%;"
                       @change="setField('selectedOrgIds',$event)"/>
    </div>

    <div class="row">
      <div class="col col-left">
        <span class="red">*</span>
        <span>答题限时：</span>
        <a-input-number v-model="limitTime" :disabled="readOnly" :min="5" class="time-limit-input"></a-input-number>
        <span>秒</span>
        <span class="tip-text">答题限时须大于5秒</span>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col col-left">
        <a-button :disabled="readOnly" type="primary" @click="saveAndNext">保存并下一步</a-button>
        <a-button :disabled="readOnly" @click="save(false)">保存本页</a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>

    <image-viewer ref="imageViewerRef"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  getPostOrgBySkill,
  saveSkillBase,
} from '../api';
import { uploadPic } from '@/api/Common';

import ImageViewer from '@/components/ImageViewer';
import SelectScriptCategory from '@components/speechSkill/SelectSkillCategory';

import UploadImage from '../components/UploadImage.vue';

export default {
  name: 'Config',
  components: {
    ImageViewer,
    SelectScriptCategory,
    UploadImage,
  },
  computed: {
    ...mapState({
      readOnly: state => state.Script.readOnly,
      script: state => state.Script.script,
    }),
  },
  watch: {
    'script.cate_1'(id) {
      this.selectedCate1 = id || 0;
      this.defaultCategory = [id, this.defaultCategory[1]];
    },
    'script.cate_2'(id) {
      this.selectedCate2 = id || 0;
      this.defaultCategory = [this.defaultCategory[0], id];
    },
    'script.title'(title) {
      this.title = title || '';
    },
    'script.content_nofill'(content) {
      this.content = content || '';
    },
    'script.post_ids_str'(ids) {
      this.selectedJobIds = ids || '';
    },
    'script.org_ids_str'(ids) {
      this.selectedOrgIds = ids || '';
    },
    'script.answer_limit_time'(time) {
      this.limitTime = time || 5;
    },
    'script.img_urls'(urls) {
      if (!urls) {
        this.imageList = [];
        return;
      }
      this.imageList = JSON.parse(urls).map((i, idx) => ({
        uid: -(idx + 1),
        name: i.split('/').pop(),
        status: 'done',
        url: i,
      }));
    },
  },
  data() {
    return {
      id: null, // 话术 id
      loading: false,
      uploading: false,
      combo: false,
      imageList: [],
      imageMaxCount: 5,
      imageSelectCount: 0,

      defaultCategory: [],

      skillList: [],

      selectedCate1: null,
      selectedCate2: null,
      title: '',
      content: '',
      selectedJobIds: '',
      selectedOrgIds: '',
      limitTime: 5, // 答题限时
    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.$store.dispatch('Script/getScript', this.id);

    this.getPostOrgBySkill();
  },
  destroyed() {
    this.$store.commit('Script/updateReadOnly', true);
    this.$store.commit('Script/updateScriptId', 0);
    this.$store.commit('Script/updateScript', {});
  },
  methods: {
    saveAndNext() {
      if (this.readOnly) {
        return;
      }
      this.save(() => {
        this.$router.replace({
          path: './keyword',
          query: { id: this.id },
        });
      });
    },
    save(next) {
      if (this.readOnly) {
        return;
      }
      if (!this.selectedCate1 || !this.selectedCate2) {
        this.defaultCategory = [];
        this.$message.warning({ content: '请选择二级话术类别' });
        return;
      }
      if (!this.selectedJobIds) {
        return this.$message.warning({ content: '请选择岗位' });
      }
      if (!this.selectedOrgIds) {
        return this.$message.warning({ content: '请选择组织机构' });
      }
      if (!this.limitTime) {
        return this.$message.warning({ content: '请填写答题限时' });
      }
      if (this.content === this.script.content_nofill || !this.script.content_nofill) {
        this.saveBase(next);
        return;
      }
      this.$notice({
        title: '温馨提示',
        content: () => <div>话术文案发生变化，请在【练习设置】中从新设置填空内容，否则在练习模式中将展示话术全文</div>,
        showMask: true,
        maskClosable: false,
        onOk: () => {
          this.saveBase(() => {
            if (next) {
              next();
            } else {
              this.$store.dispatch('Script/getScript', this.id);
            }
          });
        },
      });
    },
    cancel() {
      if (this.readOnly) {
        this.$router.back();
      } else {
        this.$confirm({
          content: () => <div>确认取消？</div>,
          onOk: () => {
            this.$router.back();
          },
        });
      }
    },

    async saveBase(next) {
      if (this.readOnly) {
        return;
      }
      if (this.combo) {
        return Promise.reject('Combo');
      }
      this.combo = false;

      const detail = {
        cate_1: this.selectedCate1,
        cate_2: this.selectedCate2,
        title: this.title,
        content: this.content,
        answer_limit_time: this.limitTime,
        org_ids: this.selectedOrgIds,
        job_ids: this.selectedJobIds,
        img_urls: this.imageList?.length ? JSON.stringify(this.imageList.map((i) => i.url)) : '',
      };
      this.id && (detail.id = this.id);

      this.loading = true;
      const data = await saveSkillBase(detail).finally(() => this.loading = false, this.combo = false);
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return Promise.reject('Fail');
      }
      this.id = data?.data?.skill_id || this.id;
      this.$message.success({ content: data?.message || '保存成功' });

      if (!this.$route?.query?.id) {
        const connector = Object.keys(this.$route?.query || {}).length ? '&' : '?';
        const path = `${this.$route.fullPath}${connector}id=${this.id}`;
        this.$router.replace(path);
      }

      next && next();
    },

    beforeUpload(file, fileList) {
      if (file.size > 1024 * 1024 * 2 + 1024) {
        this.$message.warn('每张图片大小在 2M 以内');
        return false;
      }
      if ((this.imageList?.length || 0) + (fileList?.length || 0) > 5) {
        if (!this.imageSelectCount) {
          this.imageSelectCount = fileList?.length || 0;
          this.$message.warn(`最多只能上传${this.imageMaxCount}张图片`);
        }
        this.imageSelectCount -= 1;
        return false;
      }
      return true;
    },

    async customRequest(options = {}) {
      const { file, onError, onSuccess } = options;
      if (this.uploading) {
        onError('Uploading', 'Uploading');
        return;
      }
      this.uploading = true;
      this.imageList = [...this.imageList, file];

      const params = new FormData();
      params.append('file', file);

      const data = await uploadPic(params);
      if (!data || data.error_code) {
        onError(data?.message || '上传失败');
        return;
      }

      this.imageList = [...this.imageList.map((i) => {
        if (i.uid === file.uid) {
          return {
            uid: i.uid,
            name: data?.data?.url.split('/').pop(),
            status: 'done',
            url: data?.data?.url || '',
          };
        } else {
          return { ...i };
        }
      })];

      onSuccess(data?.data?.url || '');
      this.uploading = false;
    },

    previewImage(file) {
      if (this.$refs.imageViewerRef?.show) {
        this.$refs.imageViewerRef.show({ url: file?.url || '' });
      }
    },

    removeImage(file) {
      if (!file) {
        return;
      }
      this.imageList = this.imageList.filter((i) => i.uid !== file.uid);
    },

    selectScriptCategory(event) {
      if (!event?.cate2?.id) {
        this.selectedCate1 = null;
        this.selectedCate2 = null;
        this.defaultCategory = [];
        this.$message.info({ content: '请选择二级话术类别' });
        return;
      }
      this.selectedCate1 = event?.cate1?.id || null;
      this.selectedCate2 = event?.cate2?.id || null;
      this.defaultCategory = [(this.selectedCate1 || ''), (this.selectedCate2 || '')];
    },

    async getPostOrgBySkill() {
      this.loading = true;
      const data = await getPostOrgBySkill().finally(() => {
        this.loading = false;
      });
      this.skillList = data?.data || [];
    },

    setField(field, id) {
      const fields = {
        selectedJobIds: 'post_ids_str',
        selectedOrgIds: 'org_ids_str',
      };
      this[field] = this.skillList.filter(
        (item) => item.id === +id,
      )?.[0]?.[fields[field]] || undefined;
    },
  },
};
</script>

<style scoped lang="scss">
.ss-script-config {
  width: 100%;

  .row {
    max-width: 100%;
    margin: 20px 0;
    padding: 0;

    &.hint {
      margin-top: -20px;
      padding-left: 5em;
    }

    .col {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  .script-content {
    width: 100%;
  }

  .pl-2em {
    padding-left: 2em !important;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .time-limit-input {
    width: 140px;
    margin-right: 10px;
  }
}

.select-org ::v-deep .select {
  height: auto;
}
</style>
