<template>
  <div class="full-width">
    <a-upload :fileList="list"
              :multiple="true"
              accept=".jpg, .jpeg, .png"
              list-type="picture-card"
              :disabled="uploading || loading"
              :beforeUpload="beforeUpload"
              :remove="removeImage"
              @preview="previewImage">
      <div>
        <a-icon :type="loading ? 'loading' : 'plus'"/>
        <div class="ant-upload-text">上传</div>
      </div>
    </a-upload>
    <div class="input-hint"> 图片大小在 2M 以内，建议尺寸为：606x340</div>

    <app-image-viewer ref="appImageViewerRef"
                      :url="previewImageUrl"
                      :hide-placeholder="true"
                      @close="hidePreview"/>
  </div>
</template>

<script>
import {
  uploadFileToPublic,
} from '@/api/Common';

export default {
  name: 'UploadImage',
  model: {
    prop: 'fileList',
    event: 'change',
  },
  props: ['fileList'],
  watch: {
    fileList(val) {
      this.list = [...(val || [])];
    },
  },
  data() {
    return {
      loading: false,
      uploading: false,

      list: [],

      previewImageUrl: '',
    };
  },
  methods: {
    beforeUpload(file, fileList) {
      return new Promise((resolve, reject) => {
        const types = fileList.map((i) => i.type);
        const sizes = fileList.map((i) => i.size);

        if (['jpg', 'jpeg', 'png'].every((t) => !types.includes(`image/${t}`))) {
          this.$message.info('图片仅支持 .jpg .jpeg .png 格式，请重新选择图片');
          reject('ImageTypeError');
        } else if (sizes.some((s) => s > 1024 * 1024 * 2)) {
          this.$message.info('图片大小不能超过 2M，请重新选择图片');
          reject('ImageSizeError');
        } else {
          this.uploadImageList(fileList);
          reject();
          // const imgUrl = URL.createObjectURL(file);
          // const imgEl = new Image();
          // imgEl.src = imgUrl;
          // imgEl.onload = () => {
          //   const rate = (480 / 270).toFixed(1);
          //   const imgRate = (imgEl.width / (imgEl.height || 1)).toFixed(1);
          //
          //   if (rate !== imgRate) {
          //     this.$message.info('图片支持尺寸为：480*270，请重新选择图片');
          //     reject('ImageRateError');
          //   } else {
          //     resolve();
          //   }
          // };
        }
      });
    },
    async uploadImageList(fileList) {
      if (this.uploading) {
        return;
      }
      this.uploading = true;
      const list = (fileList || []).map((file) => {
        file.status = 'uploading';
        return file;
      });
      this.list = [
        ...(this.list || []),
        ...list,
      ];
      for (let i = 0; i < fileList.length; i += 1) {
        await this.uploadImage(fileList[i]);
      }

      this.$message.success('图片上传成功');
      this.uploading = false;

      this.list = [
        ...(this.list || []).filter(
          (file) => !file.response?.error_code,
        ).map((file) => {
          file.url = file.url || file.response?.data?.url || '';
          file.status = 'done';
          return file;
        }),
      ];
      this.$emit('change', [...this.list]);
    },
    async uploadImage(file) {
      const params = new FormData();
      params.append('file', file);
      params.append('source', 'common');
      const data = await uploadFileToPublic(params);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '图片上传失败');
        return Promise.reject(data);
      }
      file.response = data;
    },

    previewImage(file) {
      this.previewImageUrl = file.url;
      if (this.$refs.appImageViewerRef?.show) {
        this.$refs.appImageViewerRef.show();
      }
    },
    hidePreview() {
      this.previewImageUrl = '';
    },

    removeImage(file) {
      if (!file) {
        return;
      }
      this.list = this.list.filter((i) => i.uid !== file.uid);
      this.$emit('change', [...this.list]);
    },
  },
};
</script>
