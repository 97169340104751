import request from '@/utils/Request';

// 话术-同义词列表
export function getSynonym(params) {
  return request({
    url: '/speechskill/admin/synonym/index',
    method: 'get',
    params
  });
}

// 话术-同义词保存
export function saveSynonym(data) {
  return request({
    url: '/speechskill/admin/synonym/save',
    method: 'post',
    data
  });
}

// 话术-同义词删除
export function deleteSynonym(data) {
  return request({
    url: '/speechskill/admin/synonym/delete',
    method: 'post',
    data
  });
}
