<template>
  <div class="ss-script-synonym">
    <div class="right-top-btn-group">
      <ImportExcel text="导入同义词" :import-type="2" @finish="importSynonym"></ImportExcel>
      <a-button @click="showCreateModal">添加同义词</a-button>
    </div>

    <div class="row">
      <div class="col">
        <span></span>
        <a-input-search placeholder="请输入关键词" enter-button="搜 索" :allow-clear="true" v-model="filterTxt"
                        @search="onSearch"/>
      </div>
    </div>

    <div class="row">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading"
               row-key="id" class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="editItem(record)">编辑</a-button>
          <a-button type="link" @click="deleteItem(record)">删除</a-button>
        </template>

        <template slot="footer">
          <Pagination :pagination="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>


    <a-modal title="编辑同义词"
             :visible="createModalShown"
             :confirm-loading="confirmLoading"
             @ok="confirmModal"
             @cancel="closeModal">
      <a-form>
        <a-form-item label="请输入原词">
          <a-textarea v-model="keyword"
                      placeholder="原词"
                      :auto-size="{ minRows: 2, maxRows: 6 }"></a-textarea>
        </a-form-item>
        <a-form-item label="请输入同义词">
          <a-textarea v-model="synonym"
                      placeholder="同义词，用逗号分隔"
                      :auto-size="{ minRows: 3, maxRows: 6 }"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {
  deleteSynonym,
  saveSynonym,
  getSynonym,
} from './api/synonym';

import Pagination, { getPagination } from '@components/Pagination';
import ImportExcel from '@components/speechSkill/ImportExcel';

export default {
  name: 'Synonym',
  components: { Pagination, ImportExcel },
  data() {
    return {
      columns: [
        { align: 'center', dataIndex: 'title', key: 'title', title: '原词' },
        { align: 'center', dataIndex: 'synonym', key: 'synonym', title: '同义词' },
        {
          align: 'center',
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          width: '180px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      filterTxt: '', // 搜索字符串
      data: [], // 话术分类列表
      loading: false,

      pagination: getPagination(),

      createModalShown: false, // 新建话术分类弹窗，新建话术二级分类弹窗
      confirmLoading: false, // 两个弹窗公用
      keyword: '',
      synonym: '',
      editedItem: {},
    };
  },
  created() {
    this.getData(1, this.pagination.pageSize);
  },
  methods: {
    importSynonym() {
    },
    onSearch() {
      this.getData(1);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, perPage) {
      this.loading = true;
      const title = this.filterTxt || '';
      const per_page = perPage || this.pagination.defaultPageSize;

      const data = await getSynonym({ title, page, per_page }).finally(() => this.loading = false);

      if (!data || data.error_code) {
        return;
      }

      this.data = data.data || [];

      const pager = { ...this.pagination };
      pager.total = data?.meta?.pagination?.total || 0;
      pager.current = page;
      pager.pageSize = per_page;
      this.pagination = pager;
    },

    showCreateModal() {
      this.createModalShown = true;
    },
    closeModal() {
      this.createModalShown = false;
      this.editedItem = null;
      this.keyword = '';
      this.synonym = '';
    },
    confirmModal() {
      this.saveItem().then(() => this.closeModal());
    },

    async saveItem() {
      if (this.confirmLoading) {
        return Promise.reject('Combo');
      }
      this.confirmLoading = true;
      const id = this.editedItem?.id || null;
      const title = this.keyword || null;
      const synonym = this.synonym || null;
      if (!title) {
        this.confirmLoading = false;
        this.$message.warning({ content: '请输入关键词' });
        return Promise.reject('EmptyTitle');
      }
      if (!synonym) {
        this.confirmLoading = false;
        this.$message.warning({ content: '请输入同义词' });
        return Promise.reject('EmptySynonym');
      }
      const data = await saveSynonym({ id, title, synonym }).finally(() => this.confirmLoading = false);
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return Promise.reject('SaveFail');
      }
      this.$message.success({ content: data?.message || '保存成功' });

      if (!id) {
        this.getData(1);
        return Promise.resolve();
      }

      const list = this.data || [];
      list.forEach(item => {
        if (item.id === id) {
          item.title = title;
          item.synonym = synonym;
        }
      });
      this.data = list;

      return Promise.resolve();
    },

    editItem(item) {
      this.editedItem = item;
      this.keyword = item.title;
      this.synonym = item.synonym;
      this.showCreateModal();
    },
    deleteItem(item) {
      this.$confirm({
        title: '提示',
        content: () => <div>确认删除 {item.title}？</div>,
        onOk: () => {
          this.confirmDelete(item);
        },
        mask: true,
        maskClosable: true,
      });
    },
    async confirmDelete(item) {
      const data = await deleteSynonym({ id: item.id });
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '删除失败' });
      }
      this.$message.success({ content: data?.message || '删除成功' });
      this.data = this.data && this.data.length ? this.data.filter(i => i.id !== item.id) : [];
    },
  },
};
</script>

<style scoped lang="scss">
.ss-script-synonym {
}
</style>
