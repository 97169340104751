<template>
  <div class="ss-make-up-modal">
    <a-modal title="开通补考"
             :visible="modalShown"
             :confirm-loading="confirmLoading"
             :mask-closable="false"
             @ok="confirmModal"
             @cancel="closeModal">
      <a-form>
        <a-form-item label="补考开始时间">
          <a-date-picker show-time :default-value="startTimeDefault"
                         :disabled-date="disabledDate" :disabled-time="disabledTime"
                         @change="startChange"></a-date-picker>
        </a-form-item>
        <a-form-item label="补考结束时间">
          <a-date-picker show-time :default-value="endTimeDefault"
                         :disabled-date="disabledDate" :disabled-time="disabledTime"
                         @change="endChange"></a-date-picker>
        </a-form-item>
        <a-form-item label="补考次数限制">
          <a-input v-model="timeLimit" placeholder="补考次数"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Moment from 'moment';
import Common from '@/utils/Common';
import { saveMakeUp } from '@/views/speechcraft/group/api';

export default {
  name: 'MakeUpModal',
  props: {
    id: { type: [String, Number], default: '' },
    examEndTimestamp: { type: Number, default: 0 },
    startTimestamp: { type: Number, default: 0 },
    endTimestamp: { type: Number, default: 0 },
    count: { type: Number, default: 0 },
  },
  data() {
    return {
      modalShown: true,
      confirmLoading: false,

      startTimeDefault: null,
      endTimeDefault: null,

      startTimeStr: '',
      endTimeStr: '',
      timeLimit: 0,
    };
  },
  created() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      if (this.startTimestamp) {
        this.startTimeDefault = Moment(this.startTimestamp * 1000);
      }
      if (this.endTimestamp) {
        this.endTimeDefault = Moment(this.endTimestamp * 1000);
      }
      this.timeLimit = this.count || 0;
    },
    startChange(date, dateString) {
      this.startTimeStr = dateString;
    },
    endChange(date, dateString) {
      this.endTimeStr = dateString;
    },

    disabledDate(date) {
      const now = new Moment();
      if (!date) {
        return true;
      }
      if (this.examEndTimestamp) {
        return date.isBefore(Moment(this.examEndTimestamp * 1000), 'date');
      }
      return date.isBefore(now, 'date');
    },
    disabledTime(date) {
      if (!date) {
        return {
          disabledHours: () => this.range(0, 24),
          disabledMinutes: () => [0, 60],
          disabledSeconds: () => [0, 60],
        };
      }
      if (this.examEndTimestamp) {
        const end = Moment(this.examEndTimestamp * 1000);
        const endHour = end.hour();
        const endMinute = date.isSame(end, 'minute') ? end.minute() : 0;
        if (date.isSame(end, 'date')) {
          return {
            disabledHours: () => endHour ? this.range(0, endHour) : [],
            disabledMinutes: () => endMinute ? this.range(0, endMinute) : [],
            disabledSeconds: () => [],
          };
        }
      }

      const now = new Moment();
      const hour = date.isSame(now, 'date') ? now.hour() : 0;
      return {
        disabledHours: () => hour ? this.range(0, hour) : [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    async confirmModal() {
      if (this.confirmLoading) {
        return;
      }
      if (new Moment().isAfter(Moment(this.startTimeStr))) {
        return this.$message.warning({ content: '补考事件不可选择当前时间之前的时间' });
      }
      if (!this.startTimeStr) {
        return this.$message.warning({ content: '请填写补考开始时间' });
      }
      if (!this.endTimeStr) {
        return this.$message.warning({ content: '请填写补考结束时间' });
      }
      if (Common.transferIosTimeString(this.startTimeStr, 1) > Common.transferIosTimeString(this.endTimeStr, 1)) {
        return this.$message.warning('开始时间要小于结束时间');
      }
      if (this.timeLimit <= 0) {
        return this.$message.warning({ content: '请填写补考次数' });
      }

      await this.save();
      this.closeModal({
        id: this.id,
        makeup_exam_num: this.timeLimit,
        makeup_exam_stime: this.startTimeStr,
        makeup_exam_etime: this.endTimeStr,
      });
    },
    closeModal(event) {
      this.$emit('close', event);
    },

    async save() {
      const data = await saveMakeUp({
        id: this.id,
        makeup_exam_num: this.timeLimit,
        makeup_exam_stime: this.startTimeStr,
        makeup_exam_etime: this.endTimeStr,
      }).finally(() => this.confirmLoading = false);

      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '保存失败' });
      }
      this.$message.success({ content: data?.message || '保存成功' });
    },
  },
};
</script>

<style scoped lang="scss">
.ss-make-up-modal {

}
</style>
