<template>
  <div class="full-width">
    <div class="right-top-btn-group">
      <a-button @click="batchPullOn">批量上架</a-button>
      <a-button @click="batchPullOff">批量下架</a-button>
      <a-button @click="batchEditItem">批量修改信息</a-button>

      <ImportExcel text="批量导入关键词" :import-type="4" @success="onSearch()"></ImportExcel>
      <ImportExcel text="批量导入" :import-type="1" @success="onSearch()"></ImportExcel>
      <a-button @click="onCreate()">添加话术</a-button>
    </div>

    <div class="row row-left">
      <div class="col col-left col-narrow">
        <SelectScriptStatus @select="selectScriptStatus"/>
      </div>

      <div class="col col-left">
        <SelectScriptCategory :individual="true" @select="selectScriptCategory"></SelectScriptCategory>
      </div>

      <div class="col">
        <span>单条话术：</span>
        <a-input-search v-model="filterTxt" :allow-clear="true" class="mnw-200"
                        placeholder="请输入题干关键词" enter-button="搜 索"
                        @search="onSearch"/>
      </div>

    </div>

    <div class="row">
      <a-table :columns="columns"
               :data-source="data"
               ref="tableRef"
               :pagination="false"
               :loading="fetching || saving"
               bordered
               :row-selection="rowSelection"
               :scroll="{x: '100%'}"
               row-key="id"
               class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button v-if="record.status === 1" type="link" size="small" @click="viewDetail(record)">详情</a-button>
            <a-button v-else type="link" size="small" @click="editItem(record)">编辑</a-button>
            <a-button v-if="record.status === 1" type="link" size="small" @click="pullOff(record)">下架</a-button>
            <a-button v-else type="link" size="small" @click="pullOn(record)">上架</a-button>
            <a-button type="link" size="small" @click="deleteItem(record)">删除</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <EditSkillModal ref="editSkillModalRef"
                    @success="onRefreshPage()"/>
  </div>
</template>

<script>
import table from '@/mixins/table';

import {
  deleteSkill,
  getSkillList,
  pullOffSkill,
  pullOnSkill,
  batchPullOnSkill,
  batchPullOffSkill,
} from './api';

import SelectScriptStatus from '@components/speechSkill/SelectSkillStatus';
import SelectScriptCategory from '@components/speechSkill/SelectSkillCategory';
import ImportExcel from '@components/speechSkill/ImportExcel';
import EditSkillModal from './components/EditSkillModal';

const columns = [
  {
    width: '60px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: 'ID',
  },
  {
    width: '180px',
    align: 'center',
    title: '题干',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '480px',
    align: 'center',
    title: '话术',
    dataIndex: 'content_nofill',
    key: 'content_nofill',
    autoExpand: true,
  },
  {
    width: '140px',
    align: 'center',
    title: '答题用时（秒）',
    key: 'answer_limit_time',
    dataIndex: 'answer_limit_time',
  },
  {
    width: '130px',
    align: 'center',
    title: '类别',
    key: 'cate_1_title',
    dataIndex: 'cate_1_title',
  },
  {
    width: '130px',
    align: 'center',
    title: '话术名称',
    key: 'cate_2_title',
    dataIndex: 'cate_2_title',
  },
  {
    width: '80px',
    align: 'center',
    title: '状态',
    key: 'status_title',
    dataIndex: 'status_title',
  },
  {
    width: '180px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'List',
  mixins: [table],
  components: {
    SelectScriptStatus,
    SelectScriptCategory,
    ImportExcel,
    EditSkillModal,
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  data() {
    return {
      originColumns: columns,
      fixColumnOffset: 1,

      selectedStatus: 0,
      selectedCate1: null,
      selectedCate2: null,
      filterTxt: '',

      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    async getData(page, pageSize) {
      this.executeGetData(async () => {
        const params = {
          page: page || 1,
          per_page: pageSize || this.pagination.defaultPageSize,
          status: this.selectedStatus,
          cate_1: this.selectedCate1,
          cate_2: this.selectedCate2,
          title: this.filterTxt,
        };
        const data = await getSkillList(params);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取数据失败');
          return Promise.reject(data);
        }
        this.data = data?.data || [];
        return Promise.resolve(data);
      });
    },

    onCreate() {
      this.$router.push({
        path: './create/config',
      });
    },

    selectScriptStatus(item) {
      this.selectedStatus = item?.id || 0;
    },

    selectScriptCategory(event) {
      this.selectedCate1 = event?.cate1?.id || null;
      this.selectedCate2 = event?.cate2?.id || null;
    },

    viewDetail(item) {
      this.$router.push({
        path: './detail/config',
        query: {
          id: item.id,
        },
      });
    },
    editItem(item) {
      this.$router.push({
        path: './edit/config',
        query: {
          id: item.id,
        },
      });
    },

    /**
     * 上架
     * @param item
     */
    pullOn(item) {
      this.$confirm({
        content: () => <div>确认上架？</div>,
        onOk: () => {
          this.confirmPullOn(item);
        },
      });
    },
    async confirmPullOn(item) {
      if (this.saving) {
        return;
      }
      this.saving = true;
      const data = await pullOnSkill({ id: item.id }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          const list = data?.data?.rel_check || [];
          this.$notice({
            title: data?.data?.message,
            content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i.title || ''))]),
          });
          return;
        }
        return this.$message.error({ content: data?.message || '上架失败' });
      }
      this.$message.success({ content: data?.message || '上架成功' });
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    /**
     * 下架
     * @param item
     */
    pullOff(item) {
      this.$confirm({
        content: () => <div>确认下架？</div>,
        onOk: () => {
          this.confirmPullOff(item);
        },
      });
    },
    async confirmPullOff(item) {
      if (this.saving) {
        return;
      }
      this.saving = true;
      const data = await pullOffSkill({ id: item.id }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          const list = data?.data?.rel_check || [];
          this.$notice({
            title: '话术关联了以下话数组不能下架',
            content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i.title || ''))]),
          });
          return;
        }
        return this.$message.error({ content: data?.message || '下架失败' });
      }
      this.$message.success({ content: data?.message || '下架成功' });
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    /**
     * 删除
     * @param item
     */
    deleteItem(item) {
      this.$confirm({
        content: () => <div>确认删除话术？</div>,
        onOk: () => {
          this.confirmDelete(item);
        },
      });
    },
    async confirmDelete(item) {
      if (this.saving) {
        return;
      }
      this.saving = true;
      const data = await deleteSkill({ id: item.id }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '删除失败' });
      }
      this.$message.success({ content: data?.message || '删除成功' });
      this.data = this.data && this.data.length ? this.data.filter(i => i.id !== item.id) : [];

      const cur = this.pagination.current;
      const page = !this.data?.length ? (cur > 1 ? cur - 1 : 1) : cur;
      this.getData(page, this.pagination.pageSize);
    },

    /**
     * 批量上架
     */
    batchPullOn() {
      if (!this.selectedRowKeys?.length) {
        this.$message.warning({ content: '请选择需要上架的话术' });
        return;
      }
      this.$confirm({
        content: () => <div>确认上架选中的话术？</div>,
        onOk: () => this.confirmBatchPullOn(),
      });
    },
    async confirmBatchPullOn() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      const data = await batchPullOnSkill({
        ids: this.selectedRowKeys?.join(',') || '',
      }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '上架失败' });
        return;
      }
      if (data?.data?.error_id_str) {
        const list = this.data?.filter(
          (i) => data?.data?.error_id_str.split(',').filter(
            (id) => !!id,
          ).map(
            (id) => +id,
          ).includes(i.id),
        );
        this.$notice({
          title: data?.data?.message || '以下话术信息不完整不能上架',
          content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i.title || ''))]),
        });
      } else {
        this.$message.success({ content: data?.message || '上架成功' });
      }

      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    /**
     * 批量下架
     */
    batchPullOff() {
      if (!this.selectedRowKeys?.length) {
        this.$message.warning({ content: '请选择需要下架的话术' });
        return;
      }
      this.$confirm({
        content: () => <div>确认下架选中的话术？</div>,
        onOk: () => this.confirmBatchPullOff(),
      });
    },
    async confirmBatchPullOff() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      const data = await batchPullOffSkill({
        ids: this.selectedRowKeys?.join(',') || '',
      }).finally(() => {
        this.saving = false;
      });
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '上架失败' });
        return;
      }
      if (data?.data?.error_id_str) {
        const list = this.data?.filter(
          (i) => data?.data?.error_id_str.split(',').filter(
            (id) => !!id,
          ).map(
            (id) => +id,
          ).includes(i.id),
        );
        this.$notice({
          title: data?.data?.message || '以下话术关联了以下话数组不能下架',
          content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i.title || ''))]),
        });
      } else {
        this.$message.success({ content: data?.message || '下架成功' });
      }

      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    /**
     * 批量编辑
     */
    batchEditItem() {
      if (!this.selectedRowKeys?.length) {
        this.$message.warning('请选择需要修改的话术');
        return;
      }
      if (this.selectedRows?.some((i) => i.status === 1)) {
        this.$message.warning('只有已下架话术才能编辑');
        return;
      }
      if (this.$refs.editSkillModalRef?.show) {
        this.$refs.editSkillModalRef.show({
          ids: this.selectedRowKeys?.join(',') || '',
          items: this.selectedRows,
        });
      }
    },
  },
};
</script>
