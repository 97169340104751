<template>
  <div class="ss-script-verification">
    <div class="row">
      <div class="col col-left">
        <span class="title"><span class="red">*</span>话术配置填写完整：</span>
        <span v-if="check && check.config" class="content pass">通过</span>
        <!--<span v-else class="content">未通过</span>-->

        <div v-if="errorMsg && errorMsg.length">
          <div v-for="(item, idx) of errorMsg" :key="idx" class="content">{{ item }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-left">
        <span class="title"><span class="red">*</span>关键词及总分：</span>
        <span v-if="check && check.keyword" class="content pass">通过</span>
        <span v-else-if="check && check.keyword === 0" class="content">总分不是100分</span>
        <span v-else>未设置</span>
      </div>
    </div>

    <div class="row">
      <div class="col col-left">
        <span class="title">练习设置：</span>
        <span v-if="check && check.practise">已设置</span>
        <span v-else>未设置</span>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col col-left">
        <a-button v-if="status === 1" type="primary" @click="pullOff">下架</a-button>
        <a-button v-else :disabled="readOnly || !check.config || !check.keyword" type="primary" @click="pullOn">
          上架
        </a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  pullOnSkill,
  pullOffSkill,
  getSkillCompleteStatus,
} from '../api';

export default {
  name: 'Verification',
  computed: {
    ...mapState({
      readOnly: state => state.Script.readOnly,
      script: state => state.Script.script,
    }),
    status() {
      return this.script.status;
    },
  },
  data() {
    return {
      id: null, // 话术 id
      loading: false,

      check: {},
      errorMsg: [],
    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.getSkillCompleteStatus();
    this.$store.dispatch('Script/getScript', this.id);
  },
  destroyed() {
    this.$store.commit('Script/updateReadOnly', true);
    this.$store.commit('Script/updateScriptId', 0);
    this.$store.commit('Script/updateScript', {});
  },
  methods: {
    cancel() {
      this.$router.back();
    },

    async getSkillCompleteStatus() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getSkillCompleteStatus({ id: this.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return Promise.reject('Fail');
      }

      this.check = data?.data?.wrong_check || {};
      const errorMsg = data?.data?.wrong_arr || [];
      this.errorMsg = errorMsg.filter(i => i.indexOf('关键词') === -1 && i.indexOf('练习填空内容') === -1);
    },

    pullOn() {
      this.$confirm({
        content: () => <div>确认上架？</div>,
        onOk: () => {
          this.confirmPullOn();
        },
      });
    },
    async confirmPullOn() {
      this.loading = true;
      const data = await pullOnSkill({ id: this.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '上架失败' });
      }
      this.$message.success({ content: data?.message || '上架成功' });
      this.cancel();
    },

    pullOff() {
      this.$confirm({
        content: () => <div>确认下架？</div>,
        onOk: () => {
          this.confirmPullOff();
        },
      });
    },
    async confirmPullOff() {
      this.loading = true;
      const data = await pullOffSkill({ id: this.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        if (data?.error_code === 501) {
          const list = data?.data?.rel_check || [];
          this.$notice({
            title: '话术关联了以下话数组不能下架',
            content: h => h('div', {}, [h('p'), ...list.map(i => h('p', i.title || ''))]),
          });
          return;
        }
        return this.$message.error({ content: data?.message || '下架失败' });
      }
      this.$message.success({ content: data?.message || '下架成功' });
      this.$store.dispatch('Script/getScript', this.id);
    },
  },
};
</script>

<style scoped lang="scss">
.ss-script-verification {
  width: 100%;

  .row {
    max-width: 100%;
    margin: 20px 0;
    padding: 0;

    .col {
      width: 100%;
      max-width: 100%;
      padding: 0;

      .title {
        display: inline-block;
        width: 160px;
        text-align: right;
      }

      .content {
        color: $danger01;
      }

      .pass {
        color: $success01;
      }
    }
  }

  .script-content {
    width: 100%;
  }
}
</style>
