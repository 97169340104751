<template>
  <div class="ss-assessment-brief">
    <div class="row">
      <div class="col">
        <span class="title"><span class="red">*</span>标题：</span>
        <a-input v-model="title" :disabled="readOnly" placeholder="标题"></a-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span class="title"><span class="red">*</span>推送岗位：</span>
        <app-select-job v-model="selectedJobIds" :disabled="readOnly" class="select-job"/>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span class="title"><span class="red">*</span>推送组织结构：</span>
        <app-select-org v-model="selectedOrgIds" :disabled="readOnly" class="select-org"/>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span class="title">调研问卷名称：</span>
        <a-input v-model="quizTitle" :disabled="readOnly" placeholder="调研问卷名称"></a-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span class="title">调研问卷地址：</span>
        <a-input v-model="quizUrl" :disabled="readOnly" placeholder="调研问卷地址"></a-input>
      </div>
    </div>
    <div class="row">
      <div class="col col-left">
        <span class="title">调研弹出模式：</span>
        <a-checkbox-group v-model="quizPopMode" :disabled="readOnly">
          <a-checkbox v-for="item of quizPopOptions" :key="item.id" :value="item.id">{{ item.name }}</a-checkbox>
        </a-checkbox-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span class="title"><span class="red">*</span>上传封面图：</span>
        <a-upload
          list-type="picture-card"
          :disabled="readOnly"
          :show-upload-list="false"
          :custom-request="customRequest">
          <img v-if="coverUrl" :src="coverUrl" alt="封面" class="cover-img"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </div>
    </div>
    <div style="padding-left: 100px;">封面尺寸：343 *193（宽*高）</div>

    <div class="row">
      <div class="col col-left">
        <span class="title">是否考核：</span>
        <a-radio-group v-model="is_examine" :disabled="readOnly">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </div>
    </div>

    <div class="row mt-30">
      <div class="col col-left">
        <a-button :disabled="readOnly" type="primary" @click="saveAndNext">保存并下一步</a-button>
        <a-button :disabled="readOnly" @click="save(false)">保存本页</a-button>
        <a-button @click="cancel">取消</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import OssUploader, { getRandomString } from '@/utils/OssUploader';

import { getOSSInfo, uploadPic } from '@/api/Common';
import { saveSkillGroup } from '../api';

export default {
  name: 'Brief',
  computed: {
    ...mapState({
      readOnly: state => state.Assessment.readOnly,
      assessment: state => state.Assessment.assessment,
    }),
  },
  watch: {
    'assessment.title'(title) {
      this.title = title;
    },
    'assessment.post_ids'(ids) {
      this.selectedJobIds = ids || '';
    },
    'assessment.org_ids'(ids) {
      this.selectedOrgIds = ids || '';
    },
    'assessment.questionnaire_title'(title) {
      this.quizTitle = title || '';
    },
    'assessment.questionnaire_url'(url) {
      this.quizUrl = url || '';
    },
    'assessment.cover_pic_url'(url) {
      this.coverUrl = url || '';
    },
    'assessment.research'(research) {
      const mode = [];
      for (let key in research) {
        if (Object.prototype.hasOwnProperty.call(research, key)) {
          if (+research[key]) {
            mode.push(key);
          }
        }
      }
      this.quizPopMode = mode;
    },
    'assessment.is_examine'(val) {
      this.is_examine = val;
    }
  },
  data() {
    return {
      id: null,

      loading: false,
      uploadingPic: false,

      title: '',
      selectedJobIds: '',
      selectedOrgIds: '',
      quizTitle: '',
      quizUrl: '',
      quizPopMode: [],
      coverUrl: '',
      is_examine: 0,

      quizPopOptions: [
        { id: 'study', name: '学习模式' },
        { id: 'practice', name: '练习模式' },
        { id: 'exam', name: '检核模式' },
      ],
    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.$store.dispatch('Assessment/getAssessment', this.id);
  },
  destroyed() {
    this.$store.commit('Assessment/updateReadOnly', true);
    this.$store.commit('Assessment/updateAssessmentId', 0);
    this.$store.commit('Assessment/updateAssessment', {});
  },
  methods: {
    saveAndNext() {
      if (this.readOnly) {
        return;
      }
      this.save(() => {
        this.$router.replace({
          path: './topic',
          query: {
            id: this.id,
          },
        });
      });
    },
    async save(next) {
      if (this.readOnly) {
        return;
      }
      if (this.loading) {
        return;
      }

      if (!this.title) {
        return this.$message.warning({ content: '请填写标题' });
      }
      if (!this.selectedJobIds) {
        return this.$message.warning({ content: '请选择推送岗位' });
      }
      if (!this.selectedOrgIds) {
        return this.$message.warning({ content: '请选择推送组织机构' });
      }
      if (!this.coverUrl) {
        return this.$message.warning({ content: '请上传封面图' });
      }

      if (!(this.quizTitle && this.quizUrl && this.quizPopMode.length || !this.quizTitle && !this.quizUrl && !this.quizPopMode.length)) {
        return this.$message.warning({ content: '请填写调研问卷' });
      }

      this.loading = true;

      const params = {
        title: this.title,
        post_ids: this.selectedJobIds,
        org_ids: this.selectedOrgIds,
        questionnaire_title: this.quizTitle,
        questionnaire_url: this.quizUrl,
        research: this.quizPopMode,
        cover_pic_url: this.coverUrl,
        is_examine: this.is_examine,
      };
      this.id && (params.id = this.id);

      const data = await saveSkillGroup(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.id = data?.data?.id || null;
      this.$message.success({ content: data?.message || '保存成功' });

      if (!this.$route?.query?.id) {
        const connector = Object.keys(this.$route?.query || {}).length ? '&' : '?';
        const path = `${this.$route.fullPath}${connector}id=${this.id}`;
        this.$router.replace(path);
      }

      next && next();
    },
    cancel() {
      this.$router.back();
    },

    customRequest({ file }) {
      // this.uploadPic(file, this.getFileName(file.name));
      this.uploadPicOvert(file);
    },

    /**
     * 上传音频文件至 OSS
     */
    async uploadPic(data, fileName) {
      this.uploadingPic = true;
      let host = '';
      let imgUrl = '';
      let key = 'speechskill';

      return new Promise((resolve, reject) => {
        new OssUploader({
          init: async (up) => {
            const oss = await this.getOssInfo(key);
            host = oss.host;
            imgUrl = oss.img_url;
            // eslint-disable-next-line require-atomic-updates
            key = oss.dir + fileName;
            const now = Date.parse(new Date()) / 1000;
            const expire = parseInt(oss.expire);
            if (expire < now + 3) {
              console.log('oss 过期了', new Date(expire * 1000));
            }
            console.log('oss 没过期');

            const multipartParams = {
              'key': key,
              'policy': oss.policy,
              'OSSAccessKeyId': oss.accessid,
              'success_action_status': '200', // 让服务端返回200,不然，默认会返回204
              'signature': oss.signature,
            };

            up.setOption({
              'url': host,
              'multipart_params': multipartParams,
            });

            up.addFile(data, fileName);

            up.start();
          },
          finish: (up, file, info) => {
            if (info.status === 200) {
              console.log('[FileUploaded] success', file, info);
              this.coverUrl = imgUrl + '/' + key;
            } else if (info.status === 203) {
              this.uploadingPic = false;
              console.log('[FileUploaded] 上传到OSS成功，但是oss访问用户设置的上传回调服务器失败，失败原因是:' + info.response);
            } else {
              this.uploadingPic = false;
              console.log(info.response);
            }
            resolve();
          },
          error: (err) => {
            this.uploadingPic = false;
            reject(err);
          },
        });
      });
    },

    /**
     * 图片上传至后台
     */
    async uploadPicOvert(file) {
      const formData = new FormData();
      formData.append('file', file);

      const data = await uploadPic(formData);
      this.coverUrl = data?.data?.url || '';
    },

    /**
     * 获取 OSS 信息
     */
    async getOssInfo(key) {
      const data = await getOSSInfo({ dir: key }).catch(() => this.uploadingPic = false);
      if (!data) {
        this.uploadingPic = false;
        this.$message.error({ message: '上传图片失败' });
        return Promise.reject(data);
      }

      return Promise.resolve(data);
    },

    getFileName(name) {
      const idx = name.lastIndexOf('.');
      const type = idx > -1 ? name.substr(idx) : 'jpg';
      return getRandomString() + type;
    },
  },
};
</script>

<style scoped lang="scss">
.ss-assessment-brief {
  .row {
    width: 450px;
    margin: 20px 0;
    padding: 0;

    .col {
      width: 100%;
      padding: 0;
    }
  }

  .col {
    .title {
      min-width: 100px;
      max-width: 100px;
      text-align: right;
    }
  }

  .cover-img {
    width: 104px;
    height: 104px;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
}
</style>
